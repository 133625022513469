import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLoginButton } from 'react-social-login-buttons';
import authAction from '../actions/authAction';

const Login = ({ location }) => {
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');

    const user = useSelector((state) => state.authReducer.user);
    const error = useSelector((state) => state.authReducer.error);
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();
        authAction.login({ account, password })(dispatch);
    };

    const onLogin = async () => {
        if (window.location.origin === 'http://localhost:8002') {
            window.open('http://localhost:3002/api/google/login', '_self');
        } else {
            window.open(`${window.location.origin}/api/google/login`, '_self');
        }
    };

    if (user) {
        const { from } = (location && location.state) || {
            from: { pathname: '/' },
        };

        return <Redirect to={from} />;
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <b>AceClub</b>Adm
                </div>
                {/* <!-- /.login-logo --> */}
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">
                            Sign in to start your session
                        </p>

                        <form method="post" onSubmit={onSubmit}>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Account"
                                    name="account"
                                    value={account}
                                    onChange={(e) => setAccount(e.target.value)}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <span className="text-danger">{error}</span>
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-4">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                    >
                                        Sign In
                                    </button>
                                </div>
                                {/* <!-- /.col --> */}
                            </div>
                        </form>
                        <div className="social-auth-links text-center mb-3">

                            <button className="btn btn-block btn-danger" onClick={onLogin}>
                                <i className="fab fa-google-plus mr-2" /> Sign in using Google+
                            </button>
                        </div>
                    </div>
                    {/* <!-- /.login-card-body --> */}
                </div>
            </div>
        </div>
    );
};

export default Login;
