import moment from 'moment';

const ActionTypes = require('../actionTypes/actActionTypes');

export const initState = {
    loading: false,
    error: null,
    data: null,
    activities: [],
    activity: {
        name: '',
        status: 'on',
        type: null,
        beginTime: '',
        endTime: '',
        setting: {},
    },
    bingoAwardSettings: [],
};

function reducer(state = initState, action) {
    let newState = {};
    Object.assign(newState, state);
    switch (action.type) {
        case ActionTypes.LOADING:
            newState.loading = true;
            newState.error = null;
            newState.message = null;
            break;
        case ActionTypes.RESET:
            newState.loading = false;
            newState.error = null;
            newState.message = null;
            break;
        case ActionTypes.ERROR:
            newState.loading = false;
            newState.error = action.data;
            break;
        case ActionTypes.SET_DATA:
            newState.loading = false;
            newState = { ...newState, ...action.data };

            // 若 SET_DATA是設定 activity
            // 若活動已過期, 設 activity.readOnly = true;
            if (action.data.activity) {
                if (
                    newState.activity.endTime &&
                    moment(newState.activity.endTime).isBefore(moment())
                ) {
                    newState.activity.readOnly = true;
                } else {
                    newState.activity.readOnly = false;
                }
            }
            break;
        case ActionTypes.SET_ACTIVITY_DATA:
            newState.loading = false;
            newState.activity = { ...newState.activity, ...action.data };
            break;
        case ActionTypes.SET_DRAGON_TASK_DATA: {
            newState.loading = false;
            const {
                type,
                dragonTasks,
                dragonTaskDeleteIds,
                dragonAwardDeleteIds,
            } = action.data;
            if (!newState.activity.dragonTaskGroup) {
                newState.activity.dragonTaskGroup = {};
            }
            if (dragonTasks) {
                newState.activity.dragonTaskGroup[type] = dragonTasks;
            }
            if (dragonTaskDeleteIds) {
                newState.activity.dragonTaskDeleteIds = dragonTaskDeleteIds;
            }
            if (dragonAwardDeleteIds) {
                newState.activity.dragonAwardDeleteIds = dragonAwardDeleteIds;
            }
            break;
        }
        case ActionTypes.SET_COPY_ACTIVITY:
            newState.loading = false;
            newState = { ...newState, ...action.data };
            newState.activity.id = null;
            newState.activity.name = `${newState.activity.name}__COPY`;
            newState.activity.status = 'off';
            newState.activity.setting.platformActId = 0;

            // 註刪 Call客名單不複製
            if (newState.activity?.registerUserList?.length > 0) {
                newState.activity.registerUserList = [];
                newState.activity.total = 0;
            }

            break;
        default:
            break;
    }

    return newState;
}

export default reducer;
