const ActionTypes = {
    LOADING: 'snake.loading',
    ERROR: 'snake.error',
    SET_DATA: 'snake.set_data',

    FETCH_NODE_CHANGE: 'snake.fetchNodeChange',
    FETCH_ROULETTE_CHANGE: 'snake.fetchRouletteChange',
    FETCH_RANK_LIST: 'snake.fetchRankList',
};

module.exports = ActionTypes;
